import React, { useEffect, useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import LazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';

import ObjectEntry from '../ObjectEntry';
import LeafletMap from '../LeafletMap';
import Label from '../Label';
import i18n from '../../i18n';
import styles from './ObjectList.module.scss';

import {
    removeSavedId,
    getIdsByKey,
    saveOrRemoveId,
    MY_LIST_KEY_OBJECTS,
    MY_LIST_KEY_EVENT_OCCURRENCES,
} from '../../utils/MyListStorage';
import { dataLayerPush } from '../../utils/datalayer';

const ObjectList = ({
    items = [],
    isMyList = false,
    showRemoveButtons = false,
    header,
    text,
}) => {
    const [objects, setObjects] = useState([]);
    const [allLabels, setAllLabels] = useState([]);
    const [activeLabels, setActiveLabels] = useState(['All']);
    const [savedItems, setSavedItems] = useState([]);
    const [viewport, setViewport] = useState(null);

    const refs = objects.reduce((acc, value) => {
        acc[value.id] = React.createRef();
        return acc;
    }, {});

    useEffect(() => {
        setObjects(items);
        if (items) {
            setAllLabels([...new Set(items.map((item) => item.label))]);
        }
    }, [items]);

    useEffect(() => {
        setSavedItems([
            ...getIdsByKey(MY_LIST_KEY_OBJECTS),
            ...getIdsByKey(MY_LIST_KEY_EVENT_OCCURRENCES),
        ]);
    }, []);

    const handleRemoveObject = (id) => {
        removeSavedId(MY_LIST_KEY_OBJECTS, id);
        setSavedItems([
            ...getIdsByKey(MY_LIST_KEY_OBJECTS),
            ...getIdsByKey(MY_LIST_KEY_EVENT_OCCURRENCES),
        ]);

        const objectArray = [...objects.filter((item) => item.id !== id)];
        setObjects(objectArray);
    };

    const handleFilterClick = (label) => {
        if (label === i18n.t('generic.all')) {
            setActiveLabels(['All']);
        } else {
            let arrayWithoutAll = activeLabels.filter(
                (label) => label !== 'All'
            );
            if (activeLabels.includes(label)) {
                arrayWithoutAll.length === 1
                    ? setActiveLabels(['All'])
                    : setActiveLabels([
                          ...arrayWithoutAll.filter((item) => item !== label),
                      ]);
            } else {
                setActiveLabels([...arrayWithoutAll, label]);
            }
        }
    };

    const handleOnMarkerClick = (id) => {
        let activeObjectArray = [
            ...objects.map((item) => ({ ...item, active: item.id == id })),
        ];
        refs[id].current.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'end',
        });
        const clickedObject = objects.find((object) => object.id == id);
        dataLayerPush({
            event: 'mapPin',
            eventCategory: 'Map',
            eventAction: 'Clicked pin',
            eventLabel: clickedObject.title,
        });

        setObjects(activeObjectArray);
    };

    const handleEntryClick = (id) => {
        let activeObjectArray = [
            ...objects.map((item) => ({ ...item, active: item.id == id })),
        ];
        const { location, title } = objects.find((o) => o.id == id);

        dataLayerPush({
            event: 'mapObject',
            eventCategory: 'Map',
            eventAction: 'Clicked object',
            eventLabel: title,
        });

        if (location && location.latitude && location.longitude) {
            setViewport({
                zoom: 15,
                center: [location.latitude, location.longitude],
            });
        }
        setObjects(activeObjectArray);
    };

    const handleAddToMyList = (id, title, label, type) => {
        let mylistKey = MY_LIST_KEY_OBJECTS;
        if (type === 'Event') {
            mylistKey = MY_LIST_KEY_EVENT_OCCURRENCES;
        }
        saveOrRemoveId(mylistKey, id, title, label);
        setSavedItems([
            ...getIdsByKey(MY_LIST_KEY_OBJECTS),
            ...getIdsByKey(MY_LIST_KEY_EVENT_OCCURRENCES),
        ]);
    };

    const transitionStyles = {
        enter: styles['ObjectList__animation-enter'],
        enterActive: styles['ObjectList__animation-enter-active'],
        exit: styles['ObjectList__animation-exit'],
        exitActive: styles['ObjectList__animation-exit-active'],
    };
    const showFilters =
        Boolean(allLabels.length) && !isMyList && !showRemoveButtons;

    return (
        <div className={styles['ObjectList']}>
            {header && text && (
                <div className={styles['ObjectList__HeaderContainer']}>
                    <h1 className={styles['ObjectList__Header']}>{header}</h1>
                    <p className={styles['ObjectList__Text']}>{text}</p>
                </div>
            )}
            <div className={styles['ObjectList__ObjectListContainer']}>
                {objects && (
                    <>
                        <LazyLoad once offset={200}>
                            <section className={styles['ObjectList__Objects']}>
                                {showFilters && (
                                    <h2
                                        className={
                                            styles['ObjectList__LabelTitle']
                                        }>
                                        {`${objects.length} ${i18n.t(
                                            'Object.listTitle'
                                        )}`}
                                    </h2>
                                )}

                                {showFilters && allLabels.length > 1 && (
                                    <ObjectListFilter
                                        allLabels={allLabels}
                                        activeLabels={activeLabels}
                                        objects={objects}
                                        handleFilterClick={handleFilterClick}
                                    />
                                )}

                                <TransitionGroup
                                    enter
                                    exit
                                    className={
                                        styles['ObjectList__ObjectContainer']
                                    }>
                                    {Boolean(objects.length) &&
                                        objects.map((item) => {
                                            if (
                                                activeLabels.includes(
                                                    item.label
                                                ) ||
                                                activeLabels.includes('All')
                                            ) {
                                                return (
                                                    <CSSTransition
                                                        transitionAppear={true}
                                                        key={item.id}
                                                        classNames={
                                                            transitionStyles
                                                        }
                                                        timeout={400}>
                                                        <ObjectEntry
                                                            ref={refs[item.id]}
                                                            onClick={
                                                                handleEntryClick
                                                            }
                                                            removeItem={() => {
                                                                handleRemoveObject(
                                                                    item.id
                                                                );
                                                            }}
                                                            {...item}
                                                            active={item.active}
                                                            isInMyList={savedItems.includes(
                                                                item.id
                                                            )}
                                                            showRemoveButton={savedItems.includes(
                                                                item.id
                                                            )}
                                                            isMyList={isMyList}
                                                            addToMyList={() => {
                                                                handleAddToMyList(
                                                                    item.id,
                                                                    item.title,
                                                                    item.label,
                                                                    item.type
                                                                );
                                                            }}
                                                        />
                                                    </CSSTransition>
                                                );
                                            }
                                        })}
                                </TransitionGroup>
                            </section>
                        </LazyLoad>
                        <section className={styles['ObjectList__MapContainer']}>
                            <LazyLoad once offset={100}>
                                <LeafletMap
                                    pins={objects}
                                    onMarkerClick={handleOnMarkerClick}
                                    labels={activeLabels}
                                    viewport={viewport}
                                    showMarkers={true}
                                    mapStyle={'none'}
                                />
                            </LazyLoad>
                        </section>
                    </>
                )}
            </div>
        </div>
    );
};

const ObjectListFilter = ({
    allLabels = [],
    activeLabels = [],
    handleFilterClick = () => null,
}) => {
    return (
        <div className={styles['ObjectList__LabelContainer']}>
            {allLabels.map((label, i) => {
                return (
                    <Label
                        key={i}
                        title={label}
                        active={activeLabels.includes(label)}
                        onClick={() => {
                            handleFilterClick(label);
                        }}
                        color="orange"
                    />
                );
            })}
            <Label
                title={i18n.t('generic.all')}
                color="green"
                onClick={() => {
                    handleFilterClick(i18n.t('generic.all'));
                }}
            />
        </div>
    );
};

ObjectListFilter.propTypes = {
    allLabels: PropTypes.array,
    activeLabels: PropTypes.array,
    handleFilterClick: PropTypes.func,
};

ObjectList.propTypes = {
    showRemoveButtons: PropTypes.bool,
    items: PropTypes.array,
    isMyList: PropTypes.bool,
};

export default ObjectList;
